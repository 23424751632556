import { DataGridPro, GridColDef, GridRowParams, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, nlNL } from '@mui/x-data-grid-pro';
import * as AssignmentAtoms from 'Atoms/AssignmentAtoms';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import * as GridAtoms from '../../Atoms/WorkAssignmentGridAtoms';
import * as AssignAtoms from '../../Atoms/AssignmentAtoms';
import { GridPanelContent } from './GridPanelContent';
import RightFilterDrawer from './RightFilterDrawer';
import FilterListIcon from "@mui/icons-material/FilterList";
import { Button, Paper, Stack } from '@mui/material';
import Checkbox from "./Checkbox";
import AddIcon from '@mui/icons-material/Add';
import AssignmentDialog from 'components/Assignment/AssignmentDialog';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import useWorkCol from './WorkAssignmentColumns';

interface WorkAssignmentGridProps {
  gridHeight: string;
}
const WorkAssignmentGrid = ({gridHeight}:WorkAssignmentGridProps) => {
  const [t, ] = useTranslation();
  const [workAssignmentData] = useAtom(GridAtoms.workAssignmentDataAtom);
  const [workAssignmentInitialData] = useAtom(GridAtoms.initialWADataAtom);
  const [selectedOperationIds, setSelectedOperationIds] = useAtom(AssignmentAtoms.selectedOperationIdsAtom);
  const [showAssignDialog, setShowAssignDialog] = useAtom(
    AssignAtoms.openAssignmentDialogAtom
  );
 const gridColumns : GridColDef[] = useWorkCol();


  // drawer state
  const [open, setOpen] = useAtom(GridAtoms.drawerOpenAtom);
  const [gridDataLoading,] = useAtom(GridAtoms.gridDataLoadingAtom);
  const customParseFormat = require('dayjs/plugin/customParseFormat');
  dayjs.extend(customParseFormat);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  
  const handleAssign = () => {
    setShowAssignDialog(true);
  };
  
  const dataGridFilter = () => {
    return (
      <GridToolbarContainer>       
        {showAssignDialog && <AssignmentDialog />}
        <Button
          variant="contained"
          startIcon={<AddIcon/>}
          color="primary"
          onClick={handleAssign}        
          disabled={selectedOperationIds.length < 1 || showAssignDialog}    
          size="small"
          sx={{ mr: 2 }}     
        >
          {t("AssignText")}
        </Button>
        <GridToolbarColumnsButton disabled={workAssignmentData.length===0}>
          </GridToolbarColumnsButton>       
        <Button
            disabled={workAssignmentInitialData.length===0}
            onClick={handleDrawerOpen}    sx={{ border: 'none !important'}} variant="outlined" startIcon={<FilterListIcon/>}>
            {t("FiltersText")}
        </Button>
        <GridToolbarDensitySelector disabled={workAssignmentData.length===0}/>
      </GridToolbarContainer>
    );
  };

  return (
     <>
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '68vh' }}>
      <Paper style={{ height: gridHeight }}>  
        { i18next.resolvedLanguage && 
          <DataGridPro
          key={("Grid" + i18next.language)}
            checkboxSelection
            disableColumnMenu
            sx={{
              '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
              '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
              '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' }       
            }}
            localeText={{
              toolbarDensity: t('GenericDensityText'),
              toolbarColumns: t('GenericColumnsText'),
              columnsPanelTextFieldLabel: t('FindColumnText'),
              columnsPanelTextFieldPlaceholder: t('ColumnNameText'),
              columnsPanelHideAllButton: t('HideAllText'),
              columnsPanelShowAllButton: t('ShowAllText'),
              toolbarDensityCompact: t('GenericCompactText'),
              toolbarDensityStandard: t('GenericStandardText'),
              toolbarDensityComfortable: t('GenericComfortableText')
            }}
            components={{
              Toolbar: dataGridFilter,            
              BaseCheckbox: (props) => {
                const title = props.disabled ? t('DisabledOperationTooltipTitleText'): "";
                return (
                  <Checkbox props={props} tooltipTitle={title}/>)
              },
              NoRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="start" marginTop={3} key={("NoOps" + i18next.resolvedLanguage)}>
                  {t('NoOperationsFoundTextForPlantText')}
                </Stack>
              ),
            }}
            loading={gridDataLoading}
            hideFooter
            getRowHeight={() => 'auto'}       
            getDetailPanelContent={({ row }) => <GridPanelContent row={row} />}
            getDetailPanelHeight={({ row }) => 'auto'}
            rows={workAssignmentData}
            columns={gridColumns}
            isRowSelectable={(params: GridRowParams) => !params.row.isOutsideSyncWindowInPast}
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedOperations = workAssignmentData.filter((row) =>
                selectedIDs.has(row.id)
              );
              setSelectedOperationIds(selectedOperations);
            }}
          />
        }
      </Paper>
      <div>
        <RightFilterDrawer
          anchor="right"
          open={open}
          onClose={handleDrawerOpen}
          onOpen={handleDrawerOpen}
        />
      </div>       
      </div>
      </>
  );
};

export default WorkAssignmentGrid;
import { IconButton } from "@mui/material";
import { gridDetailPanelExpandedRowsContentCacheSelector,
    GridRenderCellParams,
    useGridApiContext,
    useGridSelector} from "@mui/x-data-grid-pro";
    import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import React from "react";
import { useTranslation } from "react-i18next";

export const GridPanelToggle = (props: Pick<GridRenderCellParams, 'id' | 'value'>) => {
    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();
    const [t, ] = useTranslation();
  
    // To avoid calling ´getDetailPanelContent` all the time, the following selector
    // gives an object with the detail panel content for each row id.
    const contentCache = useGridSelector(
      apiRef,
      gridDetailPanelExpandedRowsContentCacheSelector,
    );
  
    // If the value is not a valid React element, it means that the row has no detail panel.
    const hasDetail = React.isValidElement(contentCache[id]);
  
    return (
      <IconButton
        size="large"
        tabIndex={-1}
        sx={{marginLeft:'-10px'}}
        disabled={!hasDetail}
        aria-label={isExpanded ? t("CloseText"): t('GenericOpenText')}
      >
        <ArrowForwardIosIcon
          sx={{
            transform: `rotateZ(${isExpanded ? 270 : 0}deg)`,
            transition: (theme) =>
              theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
              }),
          }}
          fontSize="inherit"
        />
      </IconButton>
    );
};
import {
  CSSObject,
  ClickAwayListener,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MenuItemsList } from "./MenuItemsList";
import { Menu as MenuIcon } from "@mui/icons-material";
import { Drawer as MuiDrawer, styled } from "@mui/material";
import { useAtom } from "jotai";
import { drawerAtom } from "Atoms/WorkAssignmentGridAtoms";
import { useTranslation } from "react-i18next";
import {
	Assignment as AssignmentIcon,
	Group as TeamsIcon,
	Settings as SettingsIcon,
} from '@mui/icons-material';
import { ROUTES } from "constants/routes";
import type { MenuItem } from '../types/MenuItem';

const drawerWidth = 210;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});
const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "isOpened",
})<{ isOpened: boolean }>(({
  isOpened,
  theme
}) => ({
  width: drawerWidth,
  height: "100vh",
  overflow: "auto",
  flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(isOpened && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!isOpened && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  transition: isOpened
    ? theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      })
    : theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
  "& .MuiDrawer-paper": {
    background: "#F8FAFC",
    position: "static",
    overflowX: "hidden",
  },
}));

export const Drawer = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const [isOpened, toggleIsOpened] = useAtom(drawerAtom);
  const [t, ] = useTranslation();

  const menuList: MenuItem[] = [
  	{
  		route: ROUTES.workassignment,
  		literal: t('WorkAssignmentText'),
  		Icon: AssignmentIcon,
  	},
  	{
  		route: ROUTES.teams,
  		literal: t('GenericTeamsText'),
  		Icon: TeamsIcon,
  	},
  	{
  		route: ROUTES.settings,
  		literal: t('GenericSettingsText'),
  		Icon: SettingsIcon,
  	},
  ];
  

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => toggleIsOpened(false)}
    >
      <StyledDrawer
        variant={isLargeScreen ? "permanent" : "temporary"}
        open={!isLargeScreen && isOpened ? true : false}
        onClose={(_, reason) =>
          (reason === "backdropClick" || reason === undefined) &&
          toggleIsOpened(false)
        }
        isOpened={isOpened}
      >
        {isOpened ? (
          <ListItem
            sx={{
              "&.Mui-selected": {
                backgroundColor: "#rgba(0, 89, 209, 0.12)",
                color: "#262626",
              },
            }}
            onClick={() => toggleIsOpened(!isOpened)} // Toggle the drawer state
          >
            <ListItemIcon
              sx={[
                { minWidth: "auto" },
                (theme) => ({
                  paddingRight: theme.spacing(2),
                }),
              ]}
            >
              <MenuIcon sx={{ color: "#616161" }} />
            </ListItemIcon>
            <ListItemText primary={t('GenericNavigationText')} />
          </ListItem>
        ) : (
          <IconButton
            color="inherit"
            onClick={() => toggleIsOpened(true)}
            sx={{ padding: theme.spacing(1) }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <MenuItemsList items={menuList} />
      </StyledDrawer>
    </ClickAwayListener>
  );
};

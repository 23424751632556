import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import translationEnglish from "./locales/en/translation.json"
import translationFrench from "./locales/fr/translation.json"
import translationIcelandic from "./locales/is/translation.json"
import LanguageDetector from 'i18next-browser-languagedetector';

const resources ={
    en: {
        translation: translationEnglish,
    },
    fr: {
        translation: translationFrench,
    },
    is: {
        translation: translationIcelandic,
    }
}

i18next
.use(LanguageDetector)
.use(initReactI18next)
.init({
    detection: {
        order: ['localStorage', 'navigator'],
        caches: ['localStorage'],
        convertDetectedLanguage: 'Iso15897'
    },
    supportedLngs: ['en', 'fr', 'is'],
    nonExplicitSupportedLngs: true,
    fallbackLng: 'en',
    resources,
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
});

import {
  Alert,
  AlertTitle,
  Collapse,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import { openSyncErrorAtom, syncErrorMessageAtom } from 'Atoms/WorkAssignmentGridAtoms';
import WorkAssignmentGrid from 'components/WorkAssignmnetGrid/WorkAssignmentGrid';
import WorkAssignmentHeader from 'components/WorkAssignmnetGrid/WorkAssignmentHeader';
import { SyncOperationConst } from 'constants/Constants';
import { useFetchLastSyncStatus } from 'hooks/CommonHooks';
import { useAtom } from 'jotai';
import { getLocalDateTime } from 'utilities/DatesFunction';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";

export const WorkAssignmentPage = () => {
  const { data } = useFetchLastSyncStatus(SyncOperationConst);
  const LastSync = getLocalDateTime(data?.completionDateTime.toString());
  const [t, ] = useTranslation();
  const NextSync = getLocalDateTime(data?.nextScheduleDateTime.toString());
  const [syncErrorMessage] = useAtom(syncErrorMessageAtom);
  const [openSyncError, setOpenSyncError] = useAtom(openSyncErrorAtom);
  return (
    <>
      <Grid container direction="column">
        <Grid item marginBottom="0.5em">
          <Typography variant="subtitle1" fontWeight={600}>
            {t('WorkAssignmentText')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        {syncErrorMessage.title.length>0 ? (
            <Collapse in={openSyncError}>
            <Alert variant="standard"   action={
            <IconButton
              aria-label={t('CloseText')}
              color="inherit"
              size="small"
              onClick={() => {
                setOpenSyncError(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }  sx={{mb:1}} severity="warning" title={t('SyncFailedText')}>
              <AlertTitle>{syncErrorMessage.title}</AlertTitle>
              {syncErrorMessage.description}
            </Alert>
            </Collapse>
          ) : null}        
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <WorkAssignmentHeader />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <WorkAssignmentGrid gridHeight={syncErrorMessage.title.length>0 ?'63vh':'71vh'}/>
        </Grid>
      </Grid>
      <Typography
        variant="body1"
        sx={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          fontSize: '12px',
          color: '#4E5257',
          fontWeight: 400,         
          backgroundColor: 'transparent'
        }}
      >
        {t("LastSyncText")}: {LastSync} | {t("NextSyncText")}: {NextSync}
      </Typography>
    </>
  );
};

import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Select,
  MenuItem,
  IconButton,
  SelectChangeEvent,
} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { DisplayNameAtom } from 'Atoms/AuthAtoms';
import { useAtom } from 'jotai';
import { ReactComponent as Logo } from '../assets/RioTintoLogo.svg';
import { useTranslation } from 'react-i18next';
import { selectedLanguageAtom } from 'Atoms/CommonAtoms';

export const Header = () => {
  const { t, i18n } = useTranslation();
  const [DisplayName] = useAtom(DisplayNameAtom);
  const [selectedLanguage, setSelectedLanguage] = useAtom(selectedLanguageAtom);

  function handleLanguageChange(event: SelectChangeEvent<string>) {
    setSelectedLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar sx={{ backgroundColor: 'black', color: 'secondary.contrastText' }}>
        <Toolbar sx={{ flexGrow: 1 }}>
          <Logo style={{ width: '7vw' }} />
          <Typography variant="subtitle2" sx={{ wordWrap: 'break-word', width: 120, marginLeft: 5 }} align="left">
            {t('WorkAssignmentText')}
          </Typography>
          <Typography sx={{ flexGrow: 1 }} align="right">
            <Select
              label="Language"
              value={selectedLanguage}
              onChange={handleLanguageChange}
              sx={{ color: 'white', marginRight: '30px' }}
              IconComponent={() => <IconButton disableRipple><ArrowDropDown sx={{ color: 'white' }} /></IconButton>}>
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="fr">Français(Canada)</MenuItem>
              <MenuItem value="is">Íslenska(Ísland)</MenuItem>
            </Select>
            {t('WelcomeText')}, {DisplayName}
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;

import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

function useMembersGridColumns() : GridColDef[] { 
  const [t, ] = useTranslation();

  return [
    { field: 'id', headerName: '', width: 50, flex: 0.2, renderCell:(index) => index.api.getRowIndex(index.row.id) + 1, sortable: false},
    { field: 'personnelNumber', headerName: t('PersonnelNumberText'), flex: 1 },
    { field: 'name', headerName: t('FullNameText'), flex: 1 },
    { field: 'jobTitle', headerName: t('JobTitleText'), flex: 1 },
    { field: 'isActive', headerName: t('StatusText'), flex: 1,  valueGetter: (params: GridValueGetterParams) => params.row.isActive ? t('GenericActiveText') : t('GenericInactiveText') },
    { field: 'userType', headerName: t('GenericTypeText'), flex: 1 },
    { field: 'plmAccess', headerName: t('PlmAccessText'), flex: 1, valueGetter: (params: GridValueGetterParams) => params.row.plmAccess ? t('GenericYesText') : t('GenericNoText')},
  ];
}

export default useMembersGridColumns;
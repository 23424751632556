import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  Box,
  Checkbox,
  FilterOptionsState,
  TextField,
  Typography,
  createFilterOptions,
  styled
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import React, { useEffect, useState } from "react";
import { AutoCompleteValues } from "types/AutoCompleteValues";
import { PreSelectedAutocompleteProps } from "types/PreSelectedAutocompleteProps";
import { AutoCompleteStyle } from "./AutoCompleteStyle";
import { Clear, RemoveOption, SelectAllId, SelectOption } from 'constants/Constants';
import { useTranslation } from "react-i18next";


const StyledFormControl = styled(FormControl)`
  margin: 1;
  width: 100%;
  font-size: "9px",
`;
const AutocompleteWrapper = styled(Box)<{ height?: string }>`
position: relative;
display: inline-block;
width: 100%;
max-height: ${(props) => props.height || 'auto'};
height: ${(props) => props.height || 'auto'};
`;


const PreSelectedAutocompleteSelectAll = ({
  options,
  label,
  onChange,
  selectedValues,
  required,
  isConcateText = false,
  isLoading = false,
  renderCode = false,
  useZIndex=true,
  height
}: PreSelectedAutocompleteProps) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const indeterminateIcon = <IndeterminateCheckBoxIcon fontSize="small" />;
  const [, setValues] = useState(selectedValues || []);
  const [open, setOpen] = useState(false);
  const [t, ] = useTranslation();
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [hasError, setHasError] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const indeterminateState = selectedValues.length < options.length && selectedValues.length > 0;

  const handleClearOptions = () => {
    setValues([])
    onChange([]);
    setAllSelected(false);
  };
  const handleSelectAll = (isSelected: boolean) => {
    if (isSelected) {
      setValues([...selectedValues, ...filteredOptions]);
      onChange([...selectedValues, ...filteredOptions]);
      setAllSelected(true);
    } else {
      handleClearOptions && handleClearOptions();
    }
  };

  useEffect(() => {
    setFilteredOptions(options);
    if(options.length === 0){
      setAllSelected(false);
    }
  }, [options]);

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected);
  };

  const handleChange = (
    event: React.SyntheticEvent,
    values: AutoCompleteValues[],
    reason: AutocompleteChangeReason
  ) => {
    if (reason === SelectOption || reason === RemoveOption) {
      if (values.find(option => option.id === SelectAllId)) {
        handleToggleSelectAll();
      }
      else {
        setValues(values);
        onChange(values);
        setAllSelected(false);
      }
    } else if (reason === Clear){
      handleClearOptions && handleClearOptions();
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFilteredOptions(options);
    if(options.length<1)
    {
      setHasError(true);
    }
    else
    {
      setHasError(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    let filteredOptions: AutoCompleteValues[];

    if (renderCode) {
      filteredOptions = options.filter((option) => {
        return option.code?.toLowerCase().includes(inputValue.toLowerCase());
      });
    } else {
      filteredOptions = options.filter((option) => {
        return (
          option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
          option.id.toLowerCase().includes(inputValue.toLowerCase())
        );
      });
    }
    setFilteredOptions(filteredOptions);
  };

  const getLabel = (option: AutoCompleteValues) => {
    if(option.id === SelectAllId){
      return option.name;
    }
    return isConcateText === true? (renderCode? option.code: option.id) + "-" + option.name : option.name;
  }

  const filterOptions = (options: AutoCompleteValues[], params: FilterOptionsState<AutoCompleteValues>) => {
    const filtered = filter(options, params);
    if (filtered.length < 1) {
      return filtered;
    }
    return [{ code: t('SelectAllText'), name: t('SelectAllText'), id: SelectAllId }, ...filtered];
  }

  const getSelectAllProps = (option: AutoCompleteValues) => {
    const props =
            option.id === SelectAllId
              ? { checked: allSelected, indeterminate: indeterminateState }
              : {};
    return props;
  }

  const renderOptions = (props: React.HTMLAttributes<HTMLLIElement>, option: AutoCompleteValues, state: AutocompleteRenderOptionState) => {
    var selectAllProps = getSelectAllProps(option);
    return (
      <li {...props} key={`${option.id}`}>
        {(
          <Checkbox
            icon={icon}
            indeterminateIcon={indeterminateIcon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 1 }}
            checked={state.selected}
            {...selectAllProps}
          />
        )}
        {getLabel(option)}
      </li>
    );
  }

  const renderInput = (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...params}
        size="small"
        label={label}
        variant="outlined"
        error={required && hasError}
        InputLabelProps={{
          style: { fontSize: 12 },
        }}
        onChange={handleInputChange}
      />
    );
  }

  const filter = createFilterOptions<AutoCompleteValues>();
  return (
    <AutocompleteWrapper height={height}>
    <StyledFormControl required={required} error={required && hasError}>
      <Autocomplete
        multiple
        disableCloseOnSelect
        size="small"
        limitTags={1}
        disablePortal={true}
        options={filteredOptions}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        loading={isLoading}
        noOptionsText={t('NoOptionsText')}
        loadingText={t('LoadingText')}
        value={selectedValues}
        getOptionLabel={getLabel}
        onChange={handleChange}
        style={{ width: "100%"}}
        sx={useZIndex ? AutoCompleteStyle : undefined}
        filterOptions={filterOptions}
        renderOption={renderOptions}
        renderInput={renderInput}
        isOptionEqualToValue={(option: AutoCompleteValues, value: AutoCompleteValues) => option.id === value.id}
      />
      {required && hasError && (
        <Typography
          fontSize={10}
          fontWeight={400}
          color="red"
          marginLeft={2}
          variant="subtitle2"
        >
          {label + t("isRequiredText")}
        </Typography>
      )}
    </StyledFormControl>
    </AutocompleteWrapper>
  );
};

export default PreSelectedAutocompleteSelectAll;

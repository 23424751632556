import { Avatar, AvatarGroup, Link, Stack } from "@mui/material";
import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF, 
  GridColDef
} from "@mui/x-data-grid-pro";
import * as SystemAlertImages from '../../assets/SystemAlerts';
import { GridPanelToggle } from "./GridPanelToggle";
import dayjs from "dayjs";
import SystemStatus from "./SystemStatus";
import { Project } from "constants/project";
import { AlertToolTip } from "./AlertToolTip";
import { AlertDescriptions } from "constants/AlertDescriptions";
import { useTranslation } from "react-i18next";

const SAPClientId = process.env.REACT_APP_SAPCLIENTID??'';
const SAPTransaction32URL = process.env.REACT_APP_SAPTRANSACTION32URL??'';

function useWorkCol() : GridColDef[] {
  const [t, ] = useTranslation();

  return  [{ field: '__check__', hideable: false, align: 'center',flex:0.1,sortable:false },
    { 
      field: "id", 
      headerName: "id",
      description: "id",
      hideable:false,
      hide:true,
    },
    { 
      field: "planningPlantId",
      headerName: t('WaGridPlanningPlantText'),     
      description: t('WaGridPlanningPlantText'),
      hide:true,
      hideable:false
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      hideable:false,
      renderCell: (params) => (
        <GridPanelToggle id={params.id} value={params.value} />
      ),
    },
    {
      field: "scheduledStart",
      headerName: t('WaGridScheduledStartText'),     
      description: t('WaGridScheduledStartDescText'),
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <div>{dayjs(params.value).format('DD/MM/YYYY')}</div>
            <div>{dayjs(params.value).format('HH:mm')}</div>
          </div>
        );
      },     
    },
    {
      field: "workOrderId",
      headerName: t('WaGridWorkOrderText'),     
      description: t('WaGridWorkOrderText'),
      hideable:false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack>
            <div><Link href={SAPTransaction32URL.replace(Project.ReplacementTextForWO,params.row.workOrderId).replace(Project.ReplacementTextForSAPClient,SAPClientId)} target="_blank" rel="noopener">{params.row.workOrderId} </Link></div>
            <div><Link href={SAPTransaction32URL.replace(Project.ReplacementTextForWO,params.row.workOrderId).replace(Project.ReplacementTextForSAPClient,SAPClientId)} target="_blank" rel="noopener">{params.row.workOrderDescription}</Link></div>
          </Stack>
        );
      },
    },
    {
      field: "operationNumber",
      headerName: t('WaGridOperationText'),     
      description: t('WaGridOperationText'),
      hideable:false,
      flex: 0.8, 
      renderCell: (params) => {
        return (
          <div>
          <div>{params.row.operationNumber}</div>
          <div>{params.row.operationDesc}</div>
        </div>
        );
      },
    },
    {
      field: "workCenter",
      headerName: t('WaGridWorkCenterText'),     
      description: t('WaGridWorkCenterText'),
      flex: 1, 
      renderCell: (params) => {
        return (
          <div>
           <div>{params.row.workCenter}</div>
          </div>
        )
      }, 
    },
    {
      field: "productiveUnit",
      headerName: t('WaGridProductiveUnitText'),     
      description: t('WaGridProductiveUnitText'),
      flex: 1.2, 
      renderCell: (params) => {
        return (
          <div>
            <div>{params.row.productiveUnit}</div>
            <div>{params.row.productiveUnitDesc}</div>
          </div>
        );
      },
    },
    {
      field: "functionalLocation",
      headerName: t('WaGridFunctionalLocationText'),
      description: t('WaGridFunctionalLocationText'),
      flex: 1.5, 
      renderCell: (params) => {
        return (
          <div>
          <div>{params.row.functionalLocation}</div>
          <div>{params.row.functionalLocationDesc}</div>
        </div>
        );
      },
    },
    {
      field: "teamName",
      headerName: t('WaGridTeamNameText'),     
      description: t('WaGridTeamNameText'),
      flex: 1, 
      renderCell: (params) => {
        return (
          <div>{params.row.teamName}</div>
        );
      },
    },
    {
      field: "systemCondition",
      headerName: t('WaGridSystemConditionText'),     
      description: t('WaGridSystemConditionDescText'),
      flex: 1.2,
      renderCell: (params) => {
        return (
          <div>{params.row.systemCondition}</div>
        )
      }, 
    },
    {
      field: "systemStatus",
      headerName: t('WaGridSystemStatusText'),     
      description: t('WaGridSystemStatusDescText'),
      flex: 1.2, 
      renderCell: (params) => {
        return (
          <div>
            <SystemStatus status={params.row.systemStatus}></SystemStatus>
          </div>)
      },
    },
    {
      field: "requiredPeople",
      headerName: t('WaGridRequiredPeopleText'),     
      description: t('WaGridRequiredPeopleDescText'),
      flex: 1.2,
      renderCell: (params) => {
        return (
          <div>
            <div>{params.row.requiredPeople}</div>
          </div>
        )
      }, 
    },
    {
      field: "duration",
      headerName: t('WaGridDurationText'),     
      description: t('WaGridDurationText'),
      flex: 1, 
      renderCell: (params) => {
        return (
          <div>
           <div>{params.row.duration + " H"}</div>
          </div>
        )
      }, 
    },
    {
      field: "assignedUsers",
      headerName: t('WaGridAssignedUsersText'),     
      description: t('WaGridAssignedUsersDescText'),
      align: 'left',
      flex: 1, 
      renderCell: (params) => {
        return (     
         <div>{params.row.assignedUsers.length}</div>       
        );
      },
      sortComparator: (v1, v2) => v1.length - v2.length   
    },
    {
      field: "Alerts",
      headerName: t('WaGridAlertsText'),     
      description: t('WaGridAlertsText'),
      flex: 1,
      align: 'right',
      renderCell: (params) => {
        const values = params.row.systemAlerts;
        let permitsAvatar = null;
        const alertsContent = values.map((alert: string, index: number) => {
          const imageUrl = SystemAlertImages[alert as keyof typeof SystemAlertImages];
          const alertval = AlertDescriptions[alert];
          if (alertval) {
            return (
              <div key={params.row.id + alert} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
              <Avatar alt={alert} src={imageUrl} sx={{ width: 20, height: 20, marginRight: '8px' }} />
              <span>{alertval.description}</span>
            </div>
            );
          }
          return null;
        });
        if (params.row.permitsCount > 0) {
          permitsAvatar = (
            <div key={params.row.id + t('GenericPermitsText')} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
            <Avatar alt={t('GenericPermitsText')}  src={SystemAlertImages.Permits} sx={{ width: 20, height: 20, marginRight: '8px' }}>
             </Avatar>
              <span>Permit(s)</span>         
            </div>
          );
        }  
        return (
          <AlertToolTip title={<div>{alertsContent}{permitsAvatar}</div>}>
            <AvatarGroup  max={1} sx={{
              '& .MuiAvatar-root': { width: 30, height: 30, fontSize: 14 }          
            }}>
              {values.map((alert: string, index: number) => {
                  const imageUrl = SystemAlertImages[alert as keyof typeof SystemAlertImages];
                  const alertval = AlertDescriptions[alert];
                if (alertval) {
                  return (
                    <Avatar key={params.row.id + alert} alt={alertval.description} src={imageUrl}/>
                  );
                }
                return null;
              })}
                {params.row.permitsCount > 0 && (
                  <Avatar src={SystemAlertImages.Permits} key={params.row.id + "Permits"} alt={t('GenericPermitsText')}>
                  </Avatar>
              )} 
            </AvatarGroup>
          </AlertToolTip>
        );
      },
    },
    {
      field: "plannerGroup",
      headerName: t('WaGridPlannerGroupText'),     
      description: t('WaGridPlannerGroupText'),
      hide:true,
      hideable:false
    },
    {
      field: "priority",
      headerName: t('WaGridPriorityText'),     
      description: t('WaGridPriorityText'),
      flex: 1, 
      hide:true,
      hideable:false
    },
    {
      field: "orderType",
      headerName: t('WaGridOrderTypeText'),     
      description: t('WaGridOrderTypeText'),
      flex: 1, 
      hide:true,
      hideable:false
    },
    {
      field: "revision",
      headerName: t('WaGridRevisionText'),     
      description: t('WaGridRevisionText'),
      flex: 1, 
      hide:true,
      hideable:false
    },
    {
      field: "genericField",
      headerName: t('WaGridGenericFieldText'),     
      description: t('WaGridGenericFieldText'),
      flex: 1, 
      hide:true,
      hideable:false
    }  
  ];
}

export default useWorkCol;
import { useLocation } from 'react-router-dom';
import { List, Grid } from '@mui/material';
import { MenuItems } from './MenuItem';
import { MenuItem } from '../types/MenuItem';

export const MenuItemsList = ({ items = [] }: { items?: MenuItem[] }) => {
  const { pathname } = useLocation();

  if (!items.length) return null;

  return (
    <Grid>
      <List sx={{ p: 0 }}>
        {items.map(({ literal, route, Icon }) => (
          <MenuItems
            Icon={Icon}
            literal={literal}
            route={route}
            key={route}
            selected={pathname === route}
          />
        ))}
      </List>
    </Grid>
  );
};

import * as React from 'react';
import Typography from '@mui/material/Typography';
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  TextField
} from '@mui/material';
import { DataGridPro, GridRowId } from '@mui/x-data-grid-pro';
import { TeamMember } from 'types/TeamMember';
import { useState } from 'react';
import TeamMemberSearch from './TeamMemberSearch';
import { SearchResultColumn } from './SearchResultColumn';
import { TeamDetails } from 'types/TeamDetails';
import { useAtom } from 'jotai';
import { openCreateTeamDialogAtom, openEditTeamDialogAtom } from 'Atoms/TeamAtoms';
import { useFetchTeam } from 'hooks/TeamsHooks';
import { OwnerIdAtom } from 'Atoms/AuthAtoms';
import { TableStyle } from './TeamStyles';
import { useTranslation } from "react-i18next";

type Args = {
  teamId: number;
  submitted: (team: TeamDetails) => void;
  deleted?: (id: number) => void;
};

const TeamForm = ({ teamId, submitted, deleted }: Args) => {
  const [OwnerId,] = useAtom(OwnerIdAtom);
  const [teamState, setTeamState] = useState<TeamDetails>({  id: 0, name: "",  description: "",  memberCount:0, ownerUserId:OwnerId,teamMembers: [],});
  const { data, isLoading } = useFetchTeam(teamId, true);
  const [t, ] = useTranslation();
  const [deletedMembers, setDeletedMembers] = useState<GridRowId[]>([]);
  const [anyAssignSyncMembersDeleted, setAnyAssignSyncMembersDeleted] = useState(false);
  const [multipleAssignSyncMembersDeleted, setMultipleAssignSyncMembersDeleted] = useState(false);
  const [showCreateTeam, setShowCreateTeam] = useAtom(openCreateTeamDialogAtom);
  const [showEditTeam, setShowEditTeam] = useAtom(openEditTeamDialogAtom);
  const [maxWidth] = useState<DialogProps['maxWidth']>('lg');
  const [checkedDelete, setcheckedDelete] = useState(false);
  const [hasOnePLMUser, sethasOnePLMUser] = useState(teamState?.id > 0 ? true : false);
  const [totalTeamMember, setTotalTeamMember] = useState(teamState?.memberCount || 0);
  const [nameCount, setNameCount] = useState(teamState?.name.length || 0);
  const [descriptionCount, setDescriptionCount] = useState(teamState?.description.length || 0);

  React.useEffect(() => {
    if (data) {
      setTeamState(data);
      sethasOnePLMUser(data?.id > 0 ? true : false);
      setTotalTeamMember(teamState?.memberCount || 0);
    }
  }, [data]);

  const onSubmit: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    if (checkedDelete && deleted !== undefined) {
      deleted(teamState!.id);
    } else {
      const newTeamMembers = teamState!.teamMembers.filter((member) => !deletedMembers.includes(member.id));
      const updatedTeam = {
        ...teamState!,
        teamMembers: newTeamMembers,
        memberCount: newTeamMembers.length,
      };
      setTeamState(updatedTeam);
      setTotalTeamMember(updatedTeam.teamMembers.length);
      submitted(updatedTeam);
    }
    handleClose();
  };

  const handleClose = () => {
    teamId === 0 ? setShowCreateTeam(false) : setShowEditTeam(false);
  };

  const setTeamMembers = (newTeamMembers: TeamMember[]) => {
    const existingMembers = new Map(teamState!.teamMembers.map((member) => [member.personnelNumber, member]));
    const updatedTeamMembers = [...teamState!.teamMembers];
    newTeamMembers.forEach((newMember) => {
      if (!existingMembers.has(newMember.personnelNumber)) {
        updatedTeamMembers.push(newMember);
        existingMembers.set(newMember.personnelNumber, newMember);
      }
    });
    const updatedTeam = {
      ...teamState!,
      teamMembers: updatedTeamMembers,
    };

    const countPLMUsers = updatedTeam.teamMembers.filter((member) => member.plmAccess).length;
    sethasOnePLMUser(countPLMUsers > 0);
    setTotalTeamMember(updatedTeam.teamMembers.length);
    setTeamState(updatedTeam);
  };

  const handleDeleteTeamCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setcheckedDelete(event.target.checked);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTeamState({ ...teamState!, name: e.target.value });
    setNameCount(e.target.value.length);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTeamState({ ...teamState!, description: e.target.value });
    setDescriptionCount(e.target.value.length);
  };

  const handleDeleteMember = (id: GridRowId) => {
    const members = deletedMembers;
    members.push(id);
    setDeletedMembers(members);

    const assignedDeletedMembers = teamState!.teamMembers.filter((m) => members.includes(m.id) && (m.operationsCount > 0 || m.hasSyncedOperations));
    if (assignedDeletedMembers.length > 0) {
      setAnyAssignSyncMembersDeleted(true);
    }
    const countPLMUsers = teamState!.teamMembers.filter((member) => member.plmAccess && !members.includes(member.id)).length;
    sethasOnePLMUser(countPLMUsers > 0);
    setMultipleAssignSyncMembersDeleted(assignedDeletedMembers.length > 1);
  };

  const handleUndoDeleteMember = (id: GridRowId) => {
    const members = deletedMembers;
    const index = members.indexOf(id);
    if (index > -1) {
      members.splice(index, 1);
    }
    setDeletedMembers(members);

    const assignedDeletedMembers = teamState!.teamMembers.filter((m) => members.includes(m.id) && (m.operationsCount > 0 || m.hasSyncedOperations));
    if (assignedDeletedMembers.length === 0) {
      setAnyAssignSyncMembersDeleted(false);
    }
    if (assignedDeletedMembers.length < 2) {
      setMultipleAssignSyncMembersDeleted(false);
    }
    const countPLMUsers = teamState!.teamMembers.filter((member) => member.plmAccess && !members.includes(member.id)).length;
    sethasOnePLMUser(countPLMUsers > 0);
  };

  const isTeamMemberDeleted = (id: GridRowId) => {
    return deletedMembers.includes(id);
  };

  const getDeletedMemberName = () => {
    const assignedMember = teamState!.teamMembers.filter((m) => deletedMembers.includes(m.id) && (m.operationsCount > 0 || m.hasSyncedOperations))[0];
    return assignedMember.name;
  };
  return (
    <div>
      {teamState && (
        <Dialog
          fullWidth={true}
          maxWidth={maxWidth}
          open={teamId === 0 ? showCreateTeam : showEditTeam}         
          keepMounted={false}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent>
            <Grid item container xs={12}>
              <Grid item xs={12} md={12} marginTop={0}>
                <Typography variant="subtitle1" color={'black'} fontWeight={600}>
                  {teamId === 0 ? t('CreateNewTeamText') : t('EditGenericText') + " " + teamState.name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} marginTop={1}>
                <TextField
                  id="txtTeamName"
                  label={t('TeamNameText')}
                  variant="outlined"
                  required={true}
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 40 }}
                  helperText={`${nameCount + t('FortyCharactersText')}`}
                  value={teamState.name}
                  onChange={handleNameChange}
                />
              </Grid>
              <Grid item xs={12} md={0.2} marginTop={1} />
              <Grid item xs={12} md={6} marginTop={1}>
                <TextField
                  id="txtTeamDesc"
                  label={t('DescriptionGenericText')}
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                  inputProps={{ maxLength: 120 }}
                  value={teamState.description}
                  helperText={`${descriptionCount + t('HundredTwentyCharactersText')}`}
                  onChange={handleDescriptionChange}
                />
              </Grid>
              <Grid item xs={12} md={12} marginTop={4}>
                <Typography variant="subtitle2" color={'black'} fontWeight={600}>
                  {t("AddTeamMembersText")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} marginTop={2}>
                <TeamMemberSearch data={teamState.teamMembers} setData={setTeamMembers} disable={totalTeamMember >= 100}/>
              </Grid>
              <Grid item xs={12} md={12} marginTop={2} style={{ height: '30vh' }}>
                <DataGridPro
                  sx={TableStyle}
                  disableColumnMenu
                  hideFooter
                  rows={teamState.teamMembers}
                  components={{
                    NoRowsOverlay: () => (
                      <Stack height="100%" alignItems="center" justifyContent="center">
                        {t('CreateTeamMessageText')}
                      </Stack>
                    ),
                  }}
                  columns={SearchResultColumn({
                    onDelete: handleDeleteMember,
                    onUndo: handleUndoDeleteMember,
                    isDeleted: isTeamMemberDeleted,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={12} marginTop={2}>
                {anyAssignSyncMembersDeleted && (
                  <Alert severity="warning">
                    {multipleAssignSyncMembersDeleted ? t('DeletedTeamMembersWarningText') : getDeletedMemberName() + t('DeletedTeamMemberWarningText')}
                  </Alert>
                )}
                {teamId < 1 && <Typography variant="subtitle2">{t('TeamMemberPlmAccessMessageText')}</Typography>}
              </Grid>

              <Grid item xs={12} md={12} marginTop={2} display={teamId > 0 ? 'block' : 'none'}>
                <Divider />
                <Typography variant="subtitle1" fontWeight={600} color={'black'}>
                  {t("DeleteTeamText")}
                </Typography>
                <Typography variant="subtitle2">{t("HappyToConfirmWholeTeamText")}</Typography>
                <FormControlLabel
                  control={<Checkbox checked={checkedDelete} onChange={handleDeleteTeamCheckBox} />}
                  label={t('DeleteTeamCheckboxWarningText') + teamState.name + t('TeamToBeDeletedMessage')}
                />
                {checkedDelete && (
                  <Alert severity="warning">
                    <AlertTitle>
                      {teamState.teamMembers.some((m) => m.operationsCount > 0)
                        ? t('DeleteTeamWithAssignmentsText')
                        : t('DeleteTeamWithoutAssignmentsText')}
                    </AlertTitle>
                    {t('DeleteTeamWarningText')}
                  </Alert>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              {t("CancelCapitalisedText")}
            </Button>
            <Button
              variant="contained"
              disabled={isLoading || !teamState.name || !teamState.teamMembers || !hasOnePLMUser || !(totalTeamMember <= 100)}
              color="primary"
              onClick={onSubmit}
              autoFocus
            >
              {teamId > 0 ? t('SaveGenericText') : t('CreateGenericText')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default TeamForm;

import { atom } from "jotai";
import { Operation } from "types/Operation";
import { AutoCompleteValues } from "types/AutoCompleteValues";
import { DateRange } from "@mui/x-date-pickers-pro/internal/models/dateRange";
import { Dayjs } from "dayjs";
import { defaultDateRange } from 'utilities/DatesFunction';
import { extractUniqueValues, extractUniqueValuesUsingSecondKey } from "utilities/ExtractUniqueValues";
import { WorkAssignmentFilters } from "types/WorkAssignmentFilter";
import syncErrorMessage from "types/SyncErrorMessage";

// Atoms for Work Assignment data and related filters
export const initialWADataAtom = atom<Operation[]>([]);
export const workAssignmentDataAtom = atom<Operation[]>([]);
export const gridDataLoadingAtom = atom<boolean>(true);
export const drawerAtom = atom<boolean>(false);
export const openSyncErrorAtom = atom<boolean>(true);
export const filtersAtom = atom((get) => {
  return {
    planningPlants: get(selectedPlanningPlantsAtom).map((p) => p.id),
    maintenancePlants: get(selectedMaintPlantsAtom).map((m) => m.id),
    workCenters: get(selectedWorkCentersAtom).map((w) => w.code),
    startDateFrom: get(dateRangeAtom)[0]?.format("DD/MM/YYYY"),
    startDateTo: get(dateRangeAtom)[1]?.format("DD/MM/YYYY"),
    workOrderNo: get(workOrderNoAtom),
    refreshGridData: get(refreshGridDataAtom)
  } as WorkAssignmentFilters;
});

export const dateRangeAtom = atom<DateRange<Dayjs>>([defaultDateRange.start, defaultDateRange.end]);
export const selectedPlanningPlantsAtom = atom<AutoCompleteValues[]>([]);
export const selectedMaintPlantsAtom = atom<AutoCompleteValues[]>([]);
export const selectedWorkCentersAtom = atom<AutoCompleteValues[]>([]);
export const workOrderNoAtom = atom<string>('');
export const refreshGridDataAtom = atom<number>(0);

// Atoms for dropdown options of filters
export const planningPlantRightFilterAtom = atom((get) => extractUniqueValues(get(initialWADataAtom),'planningPlantId'));
export const maintenancePlantRightFilterAtom = atom((get) => extractUniqueValues(get(initialWADataAtom),'maintenancePlantId'));
export const workCenterRightFilterAtom = atom((get) => extractUniqueValues(get(initialWADataAtom),'workCenter'));
export const functionalLocationsAtom = atom((get) => extractUniqueValues(get(initialWADataAtom),'functionalLocation'));
export const productiveUnitsAtom = atom((get) => extractUniqueValues(get(initialWADataAtom),'productiveUnit'));
export const plannerGroupsAtom = atom((get) => extractUniqueValues(get(initialWADataAtom),'plannerGroup'));
export const prioritiesAtom = atom((get) => extractUniqueValues(get(initialWADataAtom),'priority'));
export const orderTypesAtom = atom((get) => extractUniqueValues(get(initialWADataAtom),'orderType'));
export const revisionNumbersAtom = atom((get) => extractUniqueValues(get(initialWADataAtom),'revision'));
export const systemConditionAtom = atom((get) => extractUniqueValues(get(initialWADataAtom),'systemCondition'));
export const systemStatusAtom = atom((get) => extractUniqueValues(get(initialWADataAtom),'systemStatus'));
export const genericFieldsAtom = atom((get) => extractUniqueValues(get(initialWADataAtom),'genericField'));
export const teamNameAtom = atom((get) => extractUniqueValues(get(initialWADataAtom), 'teamName'));
export const systemAlertsAtom = atom((get) => extractUniqueValues(get(initialWADataAtom),'systemAlerts'));
export const personAssignedAtom = atom((get) => extractUniqueValuesUsingSecondKey(get(initialWADataAtom), 'assignedUsers', 'userName'));

// Atoms for checkboxes of filters
export const assignedAtom = atom<boolean>(false);
export const notAssignedAtom = atom<boolean>(false);
export const statutoryWorkAtom = atom<boolean>(false);
export const processSafetyAtom = atom<boolean>(false);
export const functionalSafetyAtom = atom<boolean>(false);

// Atoms for selected values of filters
export const selectedPlanningPlantRightFilterAtom = atom<string[]>([]);
export const selectedMaintenancePlantRightFilterAtom = atom<string[]>([]);
export const selectedWorkcenterRightFilterAtom = atom<string[]>([]);
export const selectedFlocAtom = atom<string[]>([]);
export const selectedProUnitsAtom = atom<string[]>([]);
export const selectedPlannerGroupsAtom = atom<string[]>([]);
export const selectedPrioritiesAtom = atom<string[]>([]);
export const selectedOrderTypesAtom= atom<string[]>([]);
export const selectedRevisionNumbersAtom = atom<string[]>([]);
export const selectedSystemConditionsAtom = atom<string[]>([]);
export const selectedSystemStatusAtom = atom<string[]>([]);
export const selectedTeamNameAtom = atom<string[]>([]);
export const selectedGenericFieldsAtom = atom<string[]>([]);
export const selectedPersonAssignmentsAtom = atom<string[]>([]);

export const drawerOpenAtom = atom(false);
export const syncErrorMessageAtom = atom<syncErrorMessage>({
  title: '',
  description: ''
});
